export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
    
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            Technology Reach and Touches your business.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                   
                  </div>
                  <div className='services-text list-style'>
                    <ul>
                     {d.text
                      ? d.text.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                     </ul>
                    </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
